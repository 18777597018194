import { EnvironmentOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskStatus, TaskStatusLocation } from "types";

import { StaticGoogleMap } from "./StaticGoogleMap";
import { StaticMarkerSet } from "./StaticMarkerSet";

export function TaskStatusLocationStaticMap({
  statusLocation,
  status,
}: {
  status: TaskStatus;
  statusLocation: TaskStatusLocation;
}) {
  const markers: Array<StaticMarkerSet> = [
    {
      config: { color: "green" },
      locations: [
        [statusLocation.coordinates[1], statusLocation.coordinates[0]],
      ],
    },
  ];

  const [t] = useTranslation();
  const [visible, setVisible] = useState(false);

  if (visible) {
    return (
      <Modal
        centered={true}
        title={status.title}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={
          <Button onClick={() => setVisible(false)}>{t("global.close")}</Button>
        }
      >
        <StaticGoogleMap
          className="tw-m-auto"
          title={status?.title}
          markers={markers}
        />
      </Modal>
    );
  }

  return (
    <Button
      type="link"
      onClick={() => {
        setVisible(true);
      }}
      icon={<EnvironmentOutlined />}
    ></Button>
  );
}
