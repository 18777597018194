import React from "react";

import { StaticMarkerSet, generateMarkers } from "./StaticMarkerSet";

export function StaticGoogleMap(props: {
  className: string;
  markers: Array<StaticMarkerSet>;
  title: string;
}) {
  const { className, markers, title } = props;
  const queryParams = generateMarkers(markers)
    .map((markerMarkup) => `markers=${markerMarkup}`)
    .join("&");

  const staticMapURL = `https://maps.googleapis.com/maps/api/staticmap?size=400x400&${queryParams}&key=${process.env.REACT_APP_GOOGLE_MAPS_STATIC_API_KEY}`;

  return (
    <img className={className} alt={title} src={encodeURI(staticMapURL)}></img>
  );
}
