import "./App.scss";

import AllProviders from "AllProviders";
import React from "react";

import Main from "./Main";

function App() {
  return (
    <AllProviders>
      <Main />
    </AllProviders>
  );
}

export default App;
