import {
  CalendarOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import moment from "moment";
import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { getRTEText } from "utils/components/RTE/RTE";
import { getTaskStatusLabel } from "utils/helpers/utils";

import { Customer, Task, TaskStatusLocation } from "../../../../types";
import { getCustomerName, getUsername } from "../../../../utils/helpers";

interface TaskInfoWindowProps extends WithTranslation {
  task: Task;
  onShowMoreDetails: () => void;
  returnJustBody: boolean;
  firstStatusUpdate: TaskStatusLocation;
  currentStatusUpdate: TaskStatusLocation;
  previousStatusUpdate: TaskStatusLocation;
  gotoNext: () => void;
  gotoPrevious: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
}
//TODO: show more details??
const standardDateTimeFormat = "MM/DD/YYYY HH:mm";

const TaskStatusLocationInfoWindow = ({
  task = {} as Task,
  returnJustBody,
  onShowMoreDetails,
  currentStatusUpdate,
  previousStatusUpdate,
  t,
  gotoNext,
  gotoPrevious,
  hasPrevious,
  hasNext,
  firstStatusUpdate,
}: TaskInfoWindowProps) => {
  const { customer = {} as Customer } = task;

  let defaultTemplate = (
    InfoIcon: any,
    title: string,
    value: string | ReactElement,
  ) => {
    return (
      <Row className="tw-mb-2">
        <Col span={1} className="tw-mr-2">
          {InfoIcon}
        </Col>
        <Col span={8} className="tw-mt-1 s-text-muted">
          {getRTEText(title)}
        </Col>
        <Col className="tw-mt-1 tw-font-medium tw-truncate s-text-dark">
          {value}
        </Col>
      </Row>
    );
  };

  let body = (
    <div className="">
      <h1 className="tw-text-lg tw-mt-0 tw-font-medium s-my-18">
        {getCustomerName(customer)}
      </h1>
      {defaultTemplate(
        <PhoneOutlined className="s-icon-color" />,
        t("jobPopup.jobDetails"),
        getRTEText(task.title),
      )}
      {defaultTemplate(
        <PhoneOutlined className="s-icon-color" />,
        t("jobPopup.status"),
        currentStatusUpdate?.status
          ? getTaskStatusLabel(currentStatusUpdate.status)
          : "",
      )}
      {defaultTemplate(
        <CalendarOutlined className="s-icon-color" />,
        t("jobPopup.dueDate"),
        task.endAt ? moment(task.endAt).format(standardDateTimeFormat) : "",
      )}
      {defaultTemplate(
        <CalendarOutlined className="s-icon-color" />,
        t("jobPopup.timeSinceJobStarted"),
        firstStatusUpdate ? (
          <div title={moment(firstStatusUpdate.createdAt).fromNow()}>
            {moment(firstStatusUpdate.createdAt).format(standardDateTimeFormat)}
          </div>
        ) : (
          ""
        ),
      )}
      {defaultTemplate(
        <CalendarOutlined className="s-icon-color" />,
        t("jobPopup.timeSinceLastUpdate"),
        previousStatusUpdate ? (
          <div
            title={moment(previousStatusUpdate.createdAt).from(
              currentStatusUpdate.createdAt,
            )}
          >
            {moment(previousStatusUpdate.createdAt).format(
              standardDateTimeFormat,
            )}
          </div>
        ) : (
          <div title={moment(currentStatusUpdate.createdAt).fromNow()}>
            {moment(currentStatusUpdate.createdAt).format(
              standardDateTimeFormat,
            )}
          </div>
        ),
      )}
      {defaultTemplate(
        <UserOutlined className="s-icon-color" />,
        t("jobPopup.assignee"),
        task?.assignees?.map((assignee) => getUsername(assignee)).join(" | "),
      )}
    </div>
  );

  return returnJustBody ? (
    body
  ) : (
    <Card bordered={false} style={{ width: 500 }} className="job-popup-margin">
      {body}

      <div className="tw-flex tw-items-center tw-justify-end s-job-card-btn">
        <div className="tw-flex tw-justify-between">
          {hasPrevious && (
            <div>
              <Button onClick={() => gotoPrevious()}>{t("global.back")}</Button>
            </div>
          )}
          {hasNext && (
            <div>
              <Button onClick={() => gotoNext()}>{t("global.next")}</Button>
            </div>
          )}
        </div>
        {/* <Button id={task._id} onClick={onShowMoreDetails}>
          {t("jobPopup.moreDetails")}
        </Button> */}
      </div>
    </Card>
  );
};

export default withTranslation()(TaskStatusLocationInfoWindow);
