import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { WithTranslation, withTranslation } from "react-i18next";
import client from "services/client";
import { SMSService, CustomerService } from "../services";

interface BulkSmsProps extends WithTranslation {
  rows: [];
  visible: boolean;
  handleClose: () => void;
}

const BulkSms = ({ t, visible, rows, handleClose }: BulkSmsProps) => {
  const [form] = Form.useForm();
  const { validateFields } = form;

  const updateTask = async (values: any) => {
    message.loading({
      content: t("bulkSms.updating"),
      duration: 0,
      key: "bulkSms.updating",
    });

    const customers = await CustomerService.find({
      query: { _id: { $in: rows } },
    })

    for (let index = 0; index < customers.data.length; index++) {
      const customer = customers.data[index];
      await SMSService.create({ recipient: customer.phone, text: values.smsBody, type: "test" }).then(
        (res: any) => {
          console.log(res);
        },
        (error: Error) => {
          console.log(error)
        },
      );
    }

    message.success({
      content: t("bulkSms.updated"),
      key: "bulkSms.updated",
    });
    message.destroy("bulkSms.updating");
    handleClose();
  };

  const handleSubmit = () => {
    validateFields().then(
      (values) => {
        const destroyMessage = (save = false) => {
          message.destroy("typeChange");
          if (save) {
            updateTask(values);
          }
        };
        message.warning({
          content: (
            <div>
              {t("bulkSms.typeWarn")}
              <Button
                size="small"
                type="primary"
                className="tw-mx-2"
                onClick={() => destroyMessage(true)}
              >
                {t("customfields.understood")}
              </Button>
              <Button size="small" onClick={() => destroyMessage(false)}>
                {t("global.cancel")}
              </Button>
            </div>
          ),
          key: "typeChange",
          duration: 0,
          className: "s-anticon-v-align",
        });
      },
      () => null,
    );
  };

  return (
    <Modal
      title={t("bulkSms.pageTitle")}
      onCancel={handleClose}
      onOk={handleSubmit}
      okText={t("global.send")}
      cancelText={t("global.cancel")}
      visible={visible}
      cancelButtonProps={{ className: "tw-mr-2" }}
      bodyStyle={{ padding: "12px 24px" }}
      destroyOnClose
      okButtonProps={{
        className: "s-btn-spinner-align tw-mr-2",
      }}
    >
      <Form form={form} labelCol={{ span: 24 }} hideRequiredMark>
        <Form.Item
          name="smsBody"
          label={
            <span className="s-label-color">{t("bulkSms.body")}</span>
          }
          className="s-label-margin mb-1"
          rules={[{ required: true, message: t("bulkSms.bodyReq") }]}
        >
          <Input.TextArea
            rows={5}
            className="tw-w-full st-field-color st-placeholder-color"
            allowClear
            autoFocus
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withTranslation()(BulkSms);
