import React, { useContext, useState } from "react";
import { Modal, Spin, message, DatePicker, Form, Button } from "antd";
import { WithTranslation, withTranslation } from "react-i18next";
import { CustomerService, TaskService, UserService } from "services";
import { CustomField, Task, User } from "types";
import UserContext from "UserContext";

interface TaskBulkUpdateProps extends WithTranslation {
  visible: boolean;
  rows: [],
  handleClose: () => void;
  loading: boolean;
}

const TaskBulkUpdate = ({ t, visible, rows, handleClose, loading }: TaskBulkUpdateProps) => {
  const [form] = Form.useForm();
  const { validateFields } = form;


  const updateTask = async (values: any) => {
    message.loading({
      content: t("taskBulkUpdate.updating"),
      duration: 0,
      key: "taskBulkUpdate.updating",
    });

    for (let index = 0; index < rows.length; index++) {
      const element = rows[index];
      await TaskService.patch(element, {
        endAt: values.endAt,
      })
    }
    
    message.success({
      content: t("taskBulkUpdate.updated"),
      key: "taskBulkUpdate.updated",
    });
    message.destroy("taskBulkUpdate.updating");

    handleClose();
  };

  const handleSubmit = () => {
    validateFields().then(
      (values) => {
        const destroyMessage = (save = false) => {
          message.destroy("typeChange");
          if (save) {
            updateTask(values);
          }
        };
        message.warning({
          content: (
            <div>
              {t("taskBulkUpdate.typeWarn")}
              <Button
                size="small"
                type="primary"
                className="tw-mx-2"
                onClick={() => destroyMessage(true)}
              >
                {t("customfields.understood")}
              </Button>
              <Button size="small" onClick={() => destroyMessage(false)}>
                {t("global.cancel")}
              </Button>
            </div>
          ),
          key: "typeChange",
          duration: 0,
          className: "s-anticon-v-align",
        });
      },
      () => null,
    );
  };

  return (
    <Modal
      title={t("taskBulkUpdate.pageTitle")}
      onCancel={handleClose}
      onOk={handleSubmit}
      okText={t("global.update")}
      cancelText={t("global.cancel")}
      visible={visible}
      cancelButtonProps={{ className: "tw-mr-2" }}
      bodyStyle={{ padding: "12px 24px" }}
      destroyOnClose
      okButtonProps={{
        className: "s-btn-spinner-align tw-mr-2",
      }}
    >
      <Form form={form} labelCol={{ span: 24 }} hideRequiredMark>
        <Form.Item
          name="endAt"
          label={
            <span className="s-label-color">{t("taskBulkUpdate.date")}</span>
          }
          className="s-label-margin mb-1"
          rules={[{ required: true, message: t("taskBulkUpdate.dateReq") }]}
        >
          <DatePicker
            className="tw-w-full st-field-color st-placeholder-color"
            format={"DD-MM-YYYY HH:mm"}
            minuteStep={15}
            showTime
            allowClear
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withTranslation()(TaskBulkUpdate);
