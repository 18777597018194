import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TaskStatusService } from "services";

import { PaginatedFeathersResponse, TaskStatus } from "../../../../types";

type TaskStatusMap = {
  [index: string]: TaskStatus;
};

function findFirmTaskStatus(): TaskStatus[] {
  return TaskStatusService.find().then(
    (res: PaginatedFeathersResponse<TaskStatus>) => res.data,
  );
}

//FIXME: fetch deleted statuses too
export function useTaskStatuses() {
  const [statusesMap, setStatusesMap] = useState({} as TaskStatusMap);
  const query = useQuery(["task-statuses"], findFirmTaskStatus);

  useEffect(() => {
    if (query.data?.length) {
      setStatusesMap(query.data.reduce((a, c) => ({ ...a, [c._id]: c }), {}));
    }
  }, [query.data]);

  return { ...query, statusesMap };
}
