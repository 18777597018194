import { Tag, Tooltip } from "antd";
import React from "react";

import { TaskStatus } from "../../../../../types";
import { getTaskStatusLabel } from "../../../../../utils/helpers/utils";

export const TaskStatusTag = ({ status }: { status: TaskStatus }) => {
  return (
    <Tooltip placement="topLeft" title={getTaskStatusLabel(status)}>
      <Tag
        className={
          (getTaskStatusLabel(status) || "").length <= 15
            ? "s-main-font tw-text-center s-rightBadge tw-text-white"
            : "s-main-font tw-text-center tw-truncate s-rightBadge tw-text-white tw-w-32"
        }
        style={{
          backgroundColor: status?.color || "#808080",
          border: "1px solid " + status?.color || "#808080",
        }}
      >
        {getTaskStatusLabel(status)}
      </Tag>
    </Tooltip>
  );
};
