import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// TODO: (tip move them in a JSON file and import them)
import resources from "./i18n/index";

function defaultLocale() {
  if (process.env.NODE_ENV === "production") {
    return "tr";
  }
  return "en";
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLocale(),

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
