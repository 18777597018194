export interface StaticMarkerSet {
  config: { color?: string; label?: string };
  locations: [[number, number]];
}

export function generateMarkers(markers: Array<StaticMarkerSet>) {
  return markers.map((markerSet) => {
    let result = "";
    if (markerSet.config) {
      if (markerSet.config.color) {
        result += `color:${markerSet.config.color}|`;
      }
      if (markerSet.config.label) {
        result += `label:${markerSet.config.label}|`;
      }
    }

    result += markerSet.locations.map((item) => item.join(",")).join("|");
    return result;
  });
}
