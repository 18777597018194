import { EyeOutlined } from "@ant-design/icons";
import { InfoWindow } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TaskStatusLocationService } from "services";
import { PaginatedFeathersResponse, Task, TaskStatusLocation } from "types";
import { getTaskStatusLabel } from "utils/helpers/utils";

import TaskStatusLocationInfoWindow from "./Components/TaskStatusLocationInfoWindow";
import { useTaskStatuses } from "./Components/useTaskStatuses";

function fetchTaskStatusLocations({
  queryKey,
}: any): Array<TaskStatusLocation> {
  const [, taskId] = queryKey;
  return TaskStatusLocationService.find({ query: { taskId } }).then(
    (res: PaginatedFeathersResponse<TaskStatusLocation>) => res.data,
  );
}

export default function TaskStatusLocations({
  task,
  onLoad,
  map,
}: {
  task: Task;
  onLoad?: () => void;
  map: any;
}) {
  const [selectedLocationId, setSelectedLocationId] = useState("");

  const { isLoading, data, isError } = useQuery(
    ["task-status-locations", task._id],
    fetchTaskStatusLocations,
    {
      enabled: !!task._id,
      onSuccess: (data) => {
        if (onLoad) {
          onLoad();
        }
      },
    },
  );

  useEffect(() => {
    if (data?.length) {
      if (Object.keys(map).length > 0) {
        let bounds = new google.maps.LatLngBounds();

        data.forEach((location) => {
          bounds.extend(
            new google.maps.LatLng(
              location?.coordinates?.[1],
              location?.coordinates?.[0],
            ),
          );
        });
        map.fitBounds(bounds);
      }
    }
  }, [data, map]);

  const { statusesMap } = useTaskStatuses();

  if (isLoading || isError || !data) {
    return <>{}</>;
  }

  const activitiesWithStatus = data.map((item) => ({
    ...item,
    status: statusesMap[item.statusId],
  }));

  return (
    <>
      {activitiesWithStatus.map((statusLocation, index) => {
        const { coordinates } = statusLocation;
        if (selectedLocationId === statusLocation?._id) {
          return (
            <InfoWindow
              onCloseClick={() => setSelectedLocationId("")}
              position={{
                lat: parseFloat(`${coordinates[1]}`),
                lng: parseFloat(`${coordinates[0]}`),
              }}
              key={statusLocation._id}
            >
              <TaskStatusLocationInfoWindow
                onShowMoreDetails={() => {
                  // setSelectedTask("");
                }}
                hasPrevious={index > 0}
                hasNext={index < activitiesWithStatus.length - 1}
                gotoNext={() =>
                  setSelectedLocationId(activitiesWithStatus[index + 1]._id)
                }
                gotoPrevious={() =>
                  setSelectedLocationId(activitiesWithStatus[index - 1]._id)
                }
                firstStatusUpdate={activitiesWithStatus[0]}
                currentStatusUpdate={activitiesWithStatus[index]}
                previousStatusUpdate={activitiesWithStatus[index - 1]}
                task={task}
                returnJustBody={false}
              />
            </InfoWindow>
          );
        }

        return (
          <InfoWindow
            key={statusLocation._id}
            position={{
              lat: parseFloat(`${coordinates[1]}`),
              lng: parseFloat(`${coordinates[0]}`),
            }}
          >
            <div
              className="tw-text-center tw-w-full tw-cursor-pointer"
              onClick={() => setSelectedLocationId(statusLocation._id || "")}
            >
              <h1>{getTaskStatusLabel(statusLocation?.status)}</h1>
              <EyeOutlined />
            </div>
          </InfoWindow>
        );
      })}
    </>
  );
}
