import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useEffect,
  useReducer,
} from "react";
import client from "services/client";
import { hasPermission } from "utils/helpers";

import { User, UserPermissions } from "../types";

interface AuthResponse {
  accessToken?: string;
  user?: User;
}

export interface BaseAuthState extends AuthResponse {
  isLoading?: boolean;
  isLoggedIn?: boolean;
  error?: null | Error;
}

export interface AuthState extends BaseAuthState {
  hasPermission: (permission: UserPermissions) => boolean;
}

const AuthStateContext = createContext<AuthState | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

function getDefaultState() {
  let result: BaseAuthState = {
    isLoading: false,
    isLoggedIn: false,
    accessToken: undefined,
    user: undefined,
    error: undefined,
  };

  return result;
}

function getInitialState() {
  let result: BaseAuthState = {};
  let savedState = localStorage.getItem("auth");
  if (savedState) {
    result = JSON.parse(savedState);
    result.isLoading = false;
  } else {
    result = getDefaultState();
  }
  return result;
}

type Action =
  | { type: "login start" }
  | { type: "login success"; payload: AuthResponse }
  | { type: "login fail"; error: null | Error }
  | { type: "logout start" }
  | { type: "logout success" }
  | { type: "logout fail"; error: null | Error }
  | { type: "reset state" };

function authReducer(_state: BaseAuthState, action: Action) {
  switch (action.type) {
    case "login start": {
      return { ..._state, isLoading: true, isLoggedIn: false, error: null };
    }
    case "login success": {
      return {
        ..._state,
        isLoading: false,
        isLoggedIn: true,
        error: null,
        ...action.payload,
      };
    }
    case "login fail": {
      return {
        isLoading: false,
        isLoggedIn: false,
        error: action.error,
      };
    }
    case "logout start": {
      return { ..._state, isLoading: true, error: null };
    }
    case "logout success": {
      return { isLoading: false, isLoggedIn: false, error: null };
    }
    case "logout fail": {
      return { ..._state, isLoading: false, error: action.error };
    }
    case "reset state": {
      return { ...getDefaultState() };
    }

    default: {
      throw new Error(`Unhandled action:`, action);
    }
  }
}

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [auth, dispatch] = useReducer(authReducer, getInitialState());

  useEffect(() => {
    client
      .reAuthenticate()
      .then((res: AuthResponse) => {
        dispatch({ type: "login success", payload: res });
      })
      .catch((err: Error) => {
        const authReloadOnce = sessionStorage.getItem("authReloadOnce");
        if (authReloadOnce !== "yes") {
          sessionStorage.setItem("authReloadOnce", "yes");
          window.location.reload();
        }
        //NOTE: this is commented because it cause error msg to display when user is not logged in
        // dispatch({ type: 'login fail', error: err });
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  const getPermission = (permission: UserPermissions) => {
    return hasPermission(auth?.user?.role, permission);
  };

  return (
    <AuthStateContext.Provider
      value={{ ...auth, hasPermission: getPermission }}
    >
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
}
